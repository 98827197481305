<!--
 * @Author: your name
 * @Date: 2020-08-03 15:46:03
 * @LastEditTime: 2020-08-04 11:15:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\login\watchlogin.vue
-->
<template>
    <div></div>
</template>
<script>
import {wxweblogin} from '../../service/login'
export default {
    data(){

    },
    methods:{
        getQueryString(name){  
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
            let r = window.location.search.substr(1).match(reg);  
            if (r != null) return unescape(r[2]); 
            return null;              
        }, 
        getWxLogin(){
            let code=this.getQueryString("code")
            this.$axios.post(`${common.userApi}/wxMLogin?code=${code}`).then(res=>{
                if(res.data.code==200){
                    this.$toast("登录成功")
                }else if(res.data.code==201){
                    this.$toast("请先关注公众号")  //下判断用户有没有关注公众号
                }else if(res.data.code==202){
                    this.$toast("请绑定账号或注册新的账号")  //提示用户将账号与微信号绑定起来，若用户之前未注册过我们的账号，则提示其直接注册新的公众号账号
                    this.openid=res.data.data   //若后端判断出用户未绑定账号或未注册，则会返回openid给前端。前端拿到openid再加上userid传给后端即可完成绑定。或者前端拿到openid再加上用户名，用户手机号等各种信息传给后端完成注册。(接口使用FormData格式)
                }else if(res.data.code==500){
                    this.$toast(res.data.msg)
                }
            }).catch(err=>{
                this.$toast('微信登录请求出错')
            })
        }
    },
    created(){
        let code = this.getQueryString("code")
      
        wxweblogin({
            code:code
        }).then(res=>{
            if(res.status === 205){
                let loading = this.$loading({
                    lock: true,
                    text: res.message,
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                });
                localStorage.setItem('watch_code',res.attachment.checkCode)
                
                window.location.href = '/createLogin.html?show=true'
                  
            }else if(res.status === 200){
                this.$store.commit("user/SETUID", res.attachment.user.uid);
                this.$store.commit("user/SETTOKEN", res.attachment.token);
                this.$store.commit("user/SETUSERDATA", res.attachment.user);
                this.$store.commit("user/SETCAR", res.attachment.user.buycarnum);
               
                window.location.href = '/account'
                
            }else if(res.status === 400){
                alert(res.message)
            }
        })
    }
}
</script>