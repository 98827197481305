/*
 * @Author: your name
 * @Date: 2020-07-03 17:33:49
 * @LastEditTime: 2020-08-03 16:43:20
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\service\login.js
 */
import { get } from "../common/query";

export const LOGIN = params => get("/login", params);
export const LOGINBYPHONE = params => get("/phonelogin", params);
export const wxweblogin = params => get("/t/wxweblogin", params)
